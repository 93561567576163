import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { PushNotificationsService } from '../services/push-notification.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent{

    constructor(public auth: AuthService, public global: GlobalService, private _notificationService: PushNotificationsService) {
    	// this._notificationService.requestPermission();
	}

	closePopup(){
		this.global.dialogVisible = false;
	}
}
